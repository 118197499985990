<template>
  <div>
    <v-btn class="ml-2" icon x-small @click="dialog = !dialog">
      <v-icon color="neuter"> mdi-cog</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent width="800px">
      <v-card>
        <v-card-title class="headline secondary white--text">{{ $t('general.titles.headers') }}</v-card-title>
        <v-card-text class="pa-6">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="selected"
                :disabled="
                    !(columns && columns.length > 0)
                  "
                :items="columns"
                :label="$t('general.titles.headers')"
                background-color="#EAEAEA80"
                color="primary"
                flat
                item-color="primary"
                item-text="name"
                item-value="key"
                multiple
                return-object
                small
                solo
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                      (+{{ selected.length - 1 }}
                      {{ $t('general.titles.others') }})
                    </span>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 360px">
                    <v-checkbox
                      :hide-details="true"
                      :input-value="attrs.inputValue"
                      class="pt-2 pb-2 mt-0"
                      color="primary"
                    >
                      <template v-slot:label>
                        <read-more
                          :classes="'mb-0'"
                          :font-size="14"
                          :max-chars="80"
                          :text="item.name"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              class="d-flex flex-wrap justify-start align-start"
              cols="12"
            >
              <v-chip-group
                v-model="selection"
                active-class="primary--text"
                column
              >
                <draggable v-model="selected" @end="dragEnd" @start="dragStart">
                  <v-chip
                    v-for="(item, index) in selected"
                    :key="index"
                    class="ma-1"
                    close
                    draggable
                    small
                    @click:close="deleteItem(index)"
                  >
                    {{ item.name }}
                  </v-chip>
                </draggable>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-btn
            small text color="neuter"
            @click="resetConfig()"
            :disabled="loadingCancel || loading"
            :loading="loadingReset"
          >
            {{ $t('general.buttons.clean') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            :loading="loadingCancel"
            small text color="neuter"
            @click="cancelDialogConfig()"
          >
            {{ $t('general.buttons.cancel') }}
          </v-btn>
          <v-btn
            :loading="loading"
            small elevation="0" color="primary" class="t-bw-primary--text"
            @click="setExtraColumnsAction()"
          >
            {{ $t('general.buttons.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReadMore from "@/components/ReadMoreComponent.vue";
import draggable from 'vuedraggable'
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";

export default {
  components: {draggable, ReadMore},

  data() {
    return {
      dialog: false,
      loading: false,
      loadingReset: false,
      loadingCancel: false,
      selection: null,
      currentTag: null
    };
  },

  created() {

  },

  computed: {
    ...mapGetters({
      columns: 'locations/GET_EXTRA_COLUMNS',
    }),
    selected: {
      get() {
        return this.$store.getters['locations/GET_SELECTED_COLUMNS'];
      },
      set(val) {
        this.$store.commit('locations/SET_SELECTED_COLUMNS', val);
      }
    }
  },

  methods: {
    ...mapActions({
      setExtraColumns: 'locations/setExtraColumns',
      fetchExtraColumns: 'locations/fetchExtraColumns'
    }),
    async setExtraColumnsAction() {
      this.loading = true

      await this.setExtraColumns(this.getByProperty(this.selected, 'key')).finally(() => {
        this.$root.$emit('setFiltersLocations')
        this.loading = false
        this.dialog = false
      })
    },
    async resetConfig() {
      this.loadingReset = true

      await this.setExtraColumns([]).finally(() => {
        this.$root.$emit('setFiltersLocations')
        this.loadingReset = false
        this.dialog = false
      })
    },
    async cancelDialogConfig() {
      this.loadingCancel = true
      await this.fetchExtraColumns().finally(() => {
        this.loadingCancel = false
        this.dialog = false
      })
    },
    dragStart() {
      if (this.selected[this.selection]) this.currentTag = this.selected[this.selection].name;
      else this.currentTag = null;
    },
    dragEnd() {
      let self = this;
      if (this.currentTag) {
        this.selected.forEach((x, i) => {
          if (x.name === self.currentTag) self.selection = i;
        });
      }
    },
    deleteItem(index) {
      this.selected.splice(index, 1)
    },
    getByProperty(array, property) {
      return _.map(array, property);
    },
  }
}
</script>

<style scoped>

</style>